import actions from './actions';

export default {
  namespaced: true,

  state: {
    smsReport: {},
    prevSmsReport: {},
  },
  getters: {
    smsReportSummary(state) {
      const { summary } = state.smsReport;
      if (summary && summary.length) {
        const data = summary.reduce((acc, val) => {
          const [key, value] = Object.values(val);
          const e = {};
          e[key] = value;

          return Object.assign({}, acc, e);
        }, {});
        return data;
      }

      return {};
    },

    prevSmsReportSummary(state) {
      const { summary } = state.prevSmsReport;

      if (summary && summary.length) {
        const data = summary.reduce((acc, val) => {
          const [key, value] = Object.values(val);
          const e = {};
          e[key] = value;

          return Object.assign({}, acc, e);
        }, {});
        return data;
      }

      return {};
    },

    reportByDate(state) {
      const { dataByDate } = state.smsReport;

      if (dataByDate && dataByDate.length) {
        return dataByDate;
      }

      return [];
    },

    reportByTopic(state) {
      const { dataByTopic } = state.smsReport;

      if (dataByTopic && dataByTopic.length) {
        return dataByTopic;
      }

      return [];
    },

    smsReportData(state) {
      const { dataByDate } = state.smsReport;
      return dataByDate;
    },

    smsTrend(state) {
      const { summary } = state.smsReport;
      const { summary: prevSummary } = state.prevSmsReport;

      if (summary && Object.keys(summary).length
        && prevSummary && Object.keys(prevSummary).length) {
        const data = summary.reduce((acc, val) => {
          const [key, value] = Object.values(val);
          const e = {};
          e[key] = value;

          return Object.assign({}, acc, e);
        }, {});

        const prevData = prevSummary.reduce((acc, val) => {
          const [key, value] = Object.values(val);
          const e = {};
          e[key] = value;

          return Object.assign({}, acc, e);
        }, {});

        return {
          sms: data.SmsCountTotal - prevData.SmsCountTotal,
          smsPercentage: (data.SmsCountTotal - prevData.SmsCountTotal) / data.SmsCountTotal,
          cost: data.Cost - prevData.Cost,
          costPercentage: (data.Cost - prevData.Cost) / data.Cost,
        };
      }

      return {
        sms: 0,
        cost: 0,
        smsPercentage: 0,
        costPercentage: 0,
      };
    },
  },

  mutations: {
    SET_SMS_REPORT(state, payload) {
      state.smsReport = payload; // eslint-disable-line no-param-reassign
    },

    SET_PREV_SMS_REPORT(state, payload) {
      state.prevSmsReport = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};
