import Vue from 'vue';
import { Promise } from 'es6-promise';
import http from '../../../../utils/http';

export default {
  updateUserAccount({ commit }, payload) {
    // const token = localStorage.getItem('WWW-Authenticate');
    return new Promise((resolve, reject) => {
      http.v1.post(`accounts/${payload.uid}/${payload.aid}`, {
        ...payload.data,
      })
        // .then(handleErrors)
        .then((response) => {
          resolve(response.data);
          commit('UPDATED_ACCOUNT', true);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getUserAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.get(`accounts/${payload.uid}/${payload.aid}`)
        .then(({ data }) => {
          commit('SET_USER_ACCOUNT', data);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updateUserProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.post(`users/${payload.uid}`, {
        ...payload.data,
      })
        .then(({ data }) => {
          commit('UPDATED_PROFILE', true);
          resolve({ data });
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getUserProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.get(`users/${payload.uid}`)
        .then(({ data }) => {
          Vue.prototype.$userData = data;
          commit('SET_USER_PROFILE', data);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  updatePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.post(`users/${payload.uid}/change-password`, {
        ...payload.data,
      })
        .then((response) => {
          // Set authorization token in local storage
          localStorage.setItem('WWW-Authenticate', response.headers.authorization);
          resolve(response.data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};
