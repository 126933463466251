import Overview from './Overview.vue';
import Forbidden from './403.vue';
import NotFound from './404.vue';
import UnderMaintenance from './UnderMaintenance.vue';

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Overview,
  },

  {
    path: '/403',
    name: 'forbidden',
    component: Forbidden,
  },

  {
    path: '/404',
    name: 'notfound',
    component: NotFound,
  },

  {
    path: '/maintenance',
    name: 'maintenance',
    component: UnderMaintenance,
  },
];
