<template>
  <div class="relative h-full flex flex-col">
    <div class="page-view-product__title text-base mt-5">
      <h3>Verif8</h3>
    </div>
    <div class="flex-auto mt-5">
      <p class="py-2 text-sm">
        Explore our new self serve feature 'Verif8' and start sending one-time-passwords in minutes using our self service OTP solution. 
      </p>
    </div>
    <div class="flex items-center justify-end">
      <a
        href="/"
        @click="later"
        class="no-underline mr-2 font-medium anchor-link"
      >
        Remind me later
      </a>
      <el-button
        @click="navigateTo('verif8')"
        type="primary"
      >
        Get started for free
      </el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Verif8View',

  props: {
  },

  methods: {
    navigateTo(route) {
      this.$emit('navigate', route);
    },
    later() {
      localStorage.setItem('verif8-later', true);
    }
  },
};
</script>
