<template>
  <div class="relative h-full flex flex-col">
    <div class="page-view-product__title text-base">
      <i class="icon-novo-video align-middle text-2xl  mr-1" /> {{ $t('sidebar_menu_parent_short.Video') }}
    </div>
    <div class="flex-auto">
      <p class="py-4 text-sm">
        {{ hasVideoApp ? $t('overview.main.video.description_active') : $t('overview.main.video.description_inactive') }}
      </p>
    </div>
    <div class="flex items-center justify-end">
      <a
        href="/video"
        class="anchor-link no-underline mr-2 font-medium"
      >
        {{ hasVideoApp ? $t('overview.explore_dashboard') : $t('overview.learn_more') }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VideoView',

  props: {
    hasVideoApp: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
