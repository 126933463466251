import { Promise } from 'es6-promise';
import jstz from 'jstimezonedetect';
import http from '../../../../utils/http';

export default {

  getSmsReport({ commit }, payload) {
    const timeInterval = 1440;
    const tz = jstz.determine().name();

    const query = `timeInterval=${timeInterval}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}&tz=${tz}`;
    this.isFetching = true;

    return new Promise((resolve, reject) => {
      http.v1.get(`reports?${query}`)
        .then(({ data }) => {
          commit(payload.commitTo, data);
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },
};
