//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ChatAppsView',

  props: {
    hasChatApp: {
      type: Boolean,
      default: false,
    },

    userData: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    showActivate() {
      if (this.userData
        && this.userData.BetaFeatures
        && this.userData.BetaFeatures.includes('CA-Prepaid')
        && !this.userData.Product_CA) {
        return true;
      }

      return false;
    },
  },
};
