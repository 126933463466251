//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VoiceView',

  props: {
    hasVoiceApp: {
      type: Boolean,
      default: false,
    },
  },
};
