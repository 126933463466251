import { Promise } from 'es6-promise';
import handleErrors from '../../../common/handle-api-errors';
import http from '../../../../utils/http';

export default {

  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      http.v1.get('countries')
        .then(({ data }) => {
          commit('SET_COUNTRIES', data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getTimezones({ commit }) {
    return new Promise((resolve, reject) => {
      http.v1.get('timezones')
        .then(({ data }) => {
          commit('SET_TIMEZONES', data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  setTimezones({ commit }, payload) {
    return new Promise((resolve) => {
      commit('SET_TIMEZONES', payload);
      resolve(payload);
    });
  },

  getSubAccounts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.get(`accounts/${payload.AccountUid}/sub-accounts`)
        .then(handleErrors)
        .then(({ data }) => {
          commit('SET_SUB_ACCOUNTS', data[0]);
          resolve(data[0]);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  getAuthUser() {
    return new Promise((resolve, reject) => {
      http.v1.get('auth/user?rolesVersion=2')
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
