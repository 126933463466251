<template>
  <section
    class="h-full main__section overview"
  >
    <h1 class="text-xl text-color font-semibold page-title ">
      {{ $t('overview.intro[0]') }}
    </h1>
    <p class="text-xs text-grey block mt-2">
      {{ $t('overview.intro[1]') }}.
    </p>
    <div class="relative page-view-container px-6 pt-6 mt-4 rounded">
      <div class="w-full">
        <div class="relative flex items-center">
          <div class="mr-4">
            <span
              class="nv-widget__user-profile rounded-full flex items-center justify-center text-xl cursor-pointer text-color"
              @click="showProfile('profile')"
            >{{ getUserAlias(userData) }}</span>
          </div>
          <p class="font-semibold text-color text-xl">
            <!-- Welcome back, {{ name }} -->
            {{ $t('overview.intro[2]', { name }) }}!
          </p>
        </div>
        <el-row
          class="product-container mt-8"
          :gutter="20"
        >
          <el-col :span="24">
              <Verif8View
                v-if="showVerif8 && !remindLater"
                class="p-4 text-color border border-color rounded page-view-product"
                @navigate="navigateTo"
              />
          </el-col>
          <el-col :span="12">
            <SmsView
              :show-sms-text="showSmsText"
              :show-send-button="showSendButton"
              class="p-4 text-color border border-color rounded page-view-product"
              @navigate="navigateTo"
            />
          </el-col>
          <el-col :span="12">
            <ChatAppsView
              :has-chat-app="hasChatApp"
              :user-data="userData"
              class="flex-1 p-4 text-color border border-color rounded page-view-product"
              @navigate="navigateTo"
            />
          </el-col>
          <el-col
            v-if="hasEntitlement('cp-video')"
            :span="12"
          >
            <VideoView
              :has-video-app="hasVideoApp"
              class="p-4 text-color border border-color rounded page-view-product"
              @navigate="navigateTo"
            />
          </el-col>
          <el-col
            v-if="hasEntitlement('cp-voice')"
            :span="12"
          >
            <VoiceView
              :has-voice-app="hasVoiceApp"
              class="p-4 text-color border border-color rounded page-view-product"
              @navigate="navigateTo"
            />
          </el-col>
          <el-col :span="12">
            <ProfileSettingsView
              class="text-color page-view-product"
              @show-profile="showProfile('profile')"
              @show-setting="showProfile('account')"
            />
          </el-col>
          <el-col :span="12">
            <SupportView
              :link="hasEntitlement('cp-support') ? '/support' : 'https://support.8x8.com/CPaaS/Proactive_Outreach'"
              class="p-4 text-color border border-color rounded page-view-product"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </section>
</template>

<script>
import singleSpa from 'single-spa';
import { mapActions, mapState, mapGetters } from 'vuex';

import Verif8View from './partials/Verif8.vue';
import SmsView from './partials/Sms.vue';
import ChatAppsView from './partials/ChatApps.vue';
import VideoView from './partials/Video.vue';
import SupportView from './partials/Support.vue';
import VoiceView from './partials/Voice.vue';
import ProfileSettingsView from './partials/ProfileSettings.vue';
import { handlePackageLimit } from '../common/handle-package-limit';

export default {
  name: 'Overview',

  components: {
    Verif8View,
    SmsView,
    ChatAppsView,
    VideoView,
    VoiceView,
    SupportView,
    ProfileSettingsView,
  },

  data() {
    return {
      resetForm: false,
      tab: 'profile',
      name: 'User',
      isAdmin: false,
      isFetching: false,
      userData: {},
      showVerif8: false,
      remindLater: false,
      showSendButton: false,
      showSmsText: true,
      hasVideoApp: false,
      hasChatApp: false,
      hasVoiceApp: false,
    };
  },

  computed: {
    ...mapState('account', ['userProfile']),
    ...mapState('dashboard', ['smsReport']),
    ...mapGetters('dashboard', ['smsReportSummary', 'prevSmsReportSummary', 'smsReportData', 'reportByDate', 'reportByTopic']),
  },

  created() {
    const user = localStorage.getItem('CPV3_User') || {};

    try {
      this.userData = JSON.parse(user);
      this.userProfile = Object.assign({}, JSON.parse(user));

      this.hasVideoApp = this.userData.Product_VI;
      this.hasChatApp = this.userData.Product_CA;
      this.hasVoiceApp = this.userData.Product_VO;

      if (!this.userData.Product_VERIF8 && this.userData.BetaFeatures.includes('WEB_Verif8_V1')) {
        this.showVerif8 = true;
      }

      if (this.userData.Roles.includes('MultichannelSender_v2')) {
        this.showSendButton = true;
      }

      this.remindLater = localStorage.getItem('verif8-later') || false;

      this.name = this.userData.Firstname || this.userData.Lastname
        ? `${this.userData.Firstname || ''} ${this.userData.Lastname || ''}` : this.userData.Login;
      this.isAdmin = this.userData.AccessLevel === 'A';
    } catch (e) {
      // do  nothing
    }
  },

  beforeDestroy() {
    this.$el.className = '';
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      getUserProfile: 'account/getUserProfile',
      getSmsReport: 'dashboard/getSmsReport',
    }),

    getUserAlias(userData) {
      if (Object.keys(userData).length) {
        if (userData.Firstname && userData.Lastname) {
          const firstName = userData.Firstname.trim().charAt(0);
          const lastName = userData.Lastname.trim().charAt(0);

          return `${firstName}${lastName}`.toUpperCase();
        }

        if (userData.Firstname && !userData.Lastname) {
          return userData.Firstname.trim().charAt(0).toUpperCase();
        }

        if (!userData.Firstname && userData.Lastname) {
          return userData.Lastname.trim().charAt(0).toUpperCase();
        }

        if (userData.Login) {
          return userData.Login.charAt(0).toUpperCase();
        }
      }

      return '';
    },

    hasEntitlement(entitlement) {
      return handlePackageLimit(entitlement);
    },

    getChange(currentTotal, prevTotal) {
      if (currentTotal === 0 && prevTotal === 0) return 0;
      if (currentTotal === 0 && prevTotal !== 0) return -100;
      if (currentTotal !== 0 && prevTotal === 0) return 100;

      return ((currentTotal - prevTotal) / prevTotal) * 100;
    },

    showProfile(tab) {
      let t = tab;
      if (tab === 'account' && !this.isAdmin) {
        t = 'profile';
      }

      const event = new Event(t);

      window.dispatchEvent(event);
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    navigateTo(app) {
      singleSpa.navigateToUrl(`/${app}`);
    },
  },
};
</script>

<style lang="scss">
#dashboard {
  .block-1 {
    min-height: 300px;
    height: 300px;
  }

  .block-2 {
    height: 150px;
  }

  .img-messaging {
    width: 40px;
    height: auto;
    vertical-align: middle;
  }

  .img-video {
    width: 40px;
    height: auto;
    vertical-align: middle;
  }

  .img-chatapp {
    width: 40px;
    height: auto;
    vertical-align: middle;
  }

  .img-voice {
    width: 40px;
    height: auto;
  }
}

.page-view-container {
  background-color: var(--background);
  .nv-widget__user-profile {
    height: 40px;
    width: 40px;
  }

  .page-view-product {
    min-height: 216px;
    height: 216px;
    margin-bottom: 20px;

    .settings, .profile {
      min-height: 216px;
      height: 216px;

      i {
        font-size: 24px;
      }
    }
  }
}

.product-container {
  margin-bottom: 20px;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}
</style>
