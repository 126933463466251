//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SupportView',

  props: {
    link: {
      type: String,
      default: '/support',
    },
  },
};
