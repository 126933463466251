//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Verif8View',

  props: {
  },

  methods: {
    navigateTo(route) {
      this.$emit('navigate', route);
    },
    later() {
      localStorage.setItem('verif8-later', true);
    }
  },
};
