<template>
  <div class="relative h-full">
    <el-row :gutter="20">
      <el-col :span="12">
        <div
          role="button"
          class="cursor-pointer p-4 text-color border border-color rounded flex justify-center items-center h-full flex-col settings font-medium anchor-link"
          @click="$emit('show-setting')"
        >
          <i class="icon-novo-gear anchor-link font-medium" />
          <span
            class="block mt-4 anchor-link"
          >
            {{ $t('overview.intro[3]') }}
          </span>
        </div>
      </el-col>
      <el-col :span="12">
        <div
          role="button"
          class="cursor-pointer p-4 text-color border border-color rounded flex justify-center items-center h-full flex-col profile font-medium anchor-link"
          @click="$emit('show-profile')"
        >
          <i class="icon-novo-user anchor-link font-medium" />
          <span
            class="block mt-4 anchor-link"
          >{{ $t('overview.intro[4]') }} </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'ProfileSettingView',

  props: {

  },
};
</script>
