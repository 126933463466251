<template>
  <div class="relative h-full flex flex-col">
    <div class="page-view-product__title text-base">
      <i class="icon-novo-whatsapp align-middle text-2xl  mr-1" /> {{ $t('sidebar_menu_parent_short.MSG') }}
    </div>
    <div class="flex-auto">
      <p class="py-4 text-sm">
        {{ (hasChatApp) ? $t('overview.main.messaging_apps.description_active') : $t('overview.main.messaging_apps.description_inactive') }}
      </p>
    </div>
    <div
      v-if="hasChatApp"
      class="flex items-center justify-end text-base"
    >
      <a
        href="/chat"
        class="anchor-link no-underline mr-2 font-medium"
      >
        {{ $t('overview.explore_dashboard') }}
      </a>
      <a
        href="/multichannel-sender/send"
        class="anchor-link no-underline ml-2 font-medium"
      >
        {{ $t('overview.initiate_conversation') }}
      </a>
    </div>
    <div
      v-else
      class="flex items-center justify-end text-base"
    >
      <a
        href="/chat"
        class="anchor-link no-underline mr-2 font-medium"
      >
        {{ showActivate() ? $t('overview.chat_apps[2]') : $t('overview.learn_more') }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChatAppsView',

  props: {
    hasChatApp: {
      type: Boolean,
      default: false,
    },

    userData: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    showActivate() {
      if (this.userData
        && this.userData.BetaFeatures
        && this.userData.BetaFeatures.includes('CA-Prepaid')
        && !this.userData.Product_CA) {
        return true;
      }

      return false;
    },
  },
};
</script>
