//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SmsView',


  props: {
    showSmsText: {
      type: Boolean,
      default: true,
    },
    showSendButton: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
  },
};
