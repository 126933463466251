import actions from './actions';

export default {
  namespaced: true,

  state: {
    userAccount: {},
    userProfile: {},
    isPasswordChanged: false,
    isAccountUpdated: false,
    isProfileUpdated: {},
  },

  mutations: {
    SET_USER_ACCOUNT(state, payload) {
      state.userAccount = payload; // eslint-disable-line
    },

    UPDATED_ACCOUNT(state, payload) {
      state.isAccountUpdated = payload; // eslint-disable-line
    },

    CHANGE_PASSWORD(state, payload) {
      state.isPasswordChanged = payload; // eslint-disable-line
    },

    UPDATED_PROFILE(state, payload) {
      state.isProfileUpdated = payload; // eslint-disable-line
    },

    SET_USER_PROFILE(state, payload) {
      state.userProfile = payload; // eslint-disable-line
    },
  },

  actions,
};
