const getUser = () => {
  let user = {};
  try {
    user = localStorage.getItem('CPV3_User');
    user = JSON.parse(user) || {};
    return user;
  } catch (e) {
    return user;
  }
};

const handlePackageLimit = (moduleId) => {
  const user = getUser();

  const { Status: hasSubscription } = user.Subscription || false;
  if (!hasSubscription) return true;

  const { Package: subscriptionPackage } = user.Subscription || {};
  let stiggEntitlements;
  if (subscriptionPackage
      && subscriptionPackage.subscriptions
      && subscriptionPackage.subscriptions.length
  ) {
    const { entitlements, inheritedEntitlements } = subscriptionPackage.subscriptions[0].plan;
    stiggEntitlements = [...entitlements, ...inheritedEntitlements];
  } else {
    stiggEntitlements = subscriptionPackage;
  }

  if (stiggEntitlements.some(item => item.feature.id.includes(moduleId))) {
    return true;
  }

  return false;
};

const getSubscribedCountry = () => {
  const user = getUser();

  const { Status: hasSubscription } = user.Subscription || false;
  if (!hasSubscription) return null;

  const { Package: subscriptionPackage } = user.Subscription || {};
  let subscribedCountry;
  if (subscriptionPackage
      && subscriptionPackage.subscriptions
      && subscriptionPackage.subscriptions.length
  ) {
    const { metadata } = subscriptionPackage.subscriptions[0].plan;
    subscribedCountry = metadata.country;
  }

  return subscribedCountry;
};

const handlePackageAddon = (addonId) => {
  const user = getUser();

  const { Status: hasSubscription } = user.Subscription || false;
  if (!hasSubscription) return false;

  const { Package: subscriptionPackage } = user.Subscription || {};
  let addons;
  if (subscriptionPackage
      && subscriptionPackage.subscriptions
      && subscriptionPackage.subscriptions.length
  ) {
    addons = subscriptionPackage.subscriptions[0].addons; // eslint-disable-line
  }

  if (addons && addons.length) {
    if (addons.some(item => item.addon.id.includes(addonId))) {
      return true;
    }
  }

  return false;
};

export {
  handlePackageLimit,
  getSubscribedCountry,
  handlePackageAddon,
};
