<template>
  <div id="dashboard">
    <RouterView />
  </div>
</template>
<script>
export default {
  name: 'Dashboard',

  // mounted() {
  //   window.addEventListener('localeChanged', () => {
  //     this.$i18n.locale = this.$getSelectedLocale();
  //   });
  // },
};
</script>
