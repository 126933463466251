<template>
  <div class="relative h-full flex flex-col">
    <div class="page-view-product__title text-base">
      <i class="icon-novo-support align-middle text-2xl mr-1" /> {{ $t('sidebar_menu[11]') }}
    </div>
    <div class="flex-auto">
      <p class="py-4 text-sm">
        {{ $t('overview.main.support.description') }}
      </p>
    </div>
    <div class="flex items-center justify-end">
      <a
        :href="link"
        class="anchor-link no-underline mr-2 font-medium"
      >
        {{ $t('overview.support_portal') }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SupportView',

  props: {
    link: {
      type: String,
      default: '/support',
    },
  },
};
</script>
