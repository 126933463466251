<template>
  <div class="relative h-full flex flex-col">
    <div class="page-view-product__title text-base">
      <i class="icon-novo-message align-middle text-2xl  mr-1" /> {{ $t('sidebar_menu_parent.SMS') }}
    </div>
    <div class="flex-auto">
      <p class="py-4 text-sm">
        {{ $t('overview.main.sms.description_active') }}
      </p>
    </div>
    <div class="flex items-center justify-end">
      <a
        href="/messaging"
        class="no-underline mr-2 font-medium anchor-link"
      >
        {{ $t('overview.explore_dashboard') }}
      </a>
      <a
        v-show="showSendButton"
        href="/multichannel-sender/send"
        class="no-underline ml-2 font-medium anchor-link"
      >
        {{ $t('overview.sms[2]') }}
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SmsView',


  props: {
    showSmsText: {
      type: Boolean,
      default: true,
    },
    showSendButton: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
  },
};
</script>
